import React, {
  FC, useState, useMemo, useRef,
} from 'react';
import * as styles from './DifferencePointAccordion.module.styl';

interface Props {
  title: string | JSX.Element;
  text: string | JSX.Element;
  addOrderClass: string;
  notice?: string;
}

interface DifferencePointState {
  isActive: boolean
}

// DifferencePointAccordion_ココが違う用
const DifferencePointAccordion: FC<Props> = ({
  title, text, addOrderClass, notice,
}) => {
  const [state, setState] = useState<DifferencePointState>({
    isActive: false,
  });

  const toggle = (): void => {
    const { isActive } = state;
    setState({
      isActive: !isActive,
    });
  };

  const targetRef = useRef<HTMLDivElement>(null);
  const panelMaxHeight = useMemo(
    () => {
      if (state.isActive) {
        if (targetRef.current?.scrollHeight) {
          const targetMaxHeight = targetRef.current?.scrollHeight + 24;
          const targetMaxHeightPx = `${targetMaxHeight}px`;
          return targetMaxHeightPx;
        }
      }
      return '0px';
    },
    [targetRef, state.isActive],
  );

  return (
    <>
      <div
        onClick={toggle}
        className={`is-relative ${styles.accordion} ${state.isActive ? `${styles.isOpen}` : ''}`}
        role="button"
        tabIndex={0}
      >
        <div className="columns is-mobile">
          <div className="column">
            <div
              className={`${styles.accordionTitle} accordion-title ${addOrderClass} has-text-primary`}
            >
              {title}
            </div>
            {notice !== null && (
              <>
                <span className={styles.accordionNotice}>
                  {notice}
                </span>
              </>
            )}
          </div>
          <div className={`column is-narrow ${styles.iconArea}`}>
            <i
              className={`icon is-small has-background-black ${
                styles.accordionIcon
              } ${state.isActive ? 'icon-minus' : 'icon-plus'}`}
            />
          </div>
        </div>
      </div>
      <div
        ref={targetRef}
        className={`${styles.panel} ${state.isActive ? `${styles.isOpen}` : ''}`}
        style={{ maxHeight: panelMaxHeight }}
      >
        <div
          className="is-size-5 is-padding-3"
        >
          {text}
        </div>
      </div>
    </>
  );
};

export default DifferencePointAccordion;
